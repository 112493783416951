.game-card {
  background-repeat: no-repeat;
  width: auto;
  height: 100%;
  background-size: cover;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    ),
    var(--img);
}
